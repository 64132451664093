import React, {useState, useEffect} from 'react'
import Markdown from 'marked-react'
import styled from 'styled-components'
import MarkdownEditor from 'lib/ui/form/TextEditor/MarkdownEditor'
import {useLayout} from 'organization/Obie/Layout'
import {
  OBIE_NEW_LINE,
  OBIE_RESPONSE_SPLITER,
} from 'organization/Obie/ObieServiceProvider'

const SPEED = 10

type EffectProps = {
  text: string
  onComplete?: () => void
  containerRef?: React.RefObject<HTMLDivElement>
  autoScroll?: boolean
  updateCompletion?: (newValue: string) => void
}

// TypingEffect Component
export function TypingEffect(
  props: EffectProps & {
    className?: string
  },
) {
  const {text, onComplete, autoScroll, containerRef} = props
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)

  const [finished, setFinished] = useState(false)

  useEffect(() => {
    const regex = /\|$/

    if (text && index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev.replace(regex, '') + text[index] + '|')
        setIndex(index + 1)
      }, SPEED)

      return () => clearTimeout(timeout)
    } else {
      onComplete && onComplete() // Notify when typing is complete

      setFinished(true)
      setDisplayedText((prev) => prev.replace(regex, ''))
    }
  }, [index, text, onComplete, setFinished])

  useEffect(() => {
    if (autoScroll && containerRef?.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [displayedText, autoScroll, containerRef])

  if (finished && props.updateCompletion) {
    return (
      <TypedText>
        <MarkdownEditor
          data={displayedText}
          onChange={props.updateCompletion}
          theme="Dark"
        />
      </TypedText>
    )
  }
  return (
    <TypedText withPadding className={props.className}>
      <Markdown>{displayedText}</Markdown>
    </TypedText>
  )
}

// FirstTextComponent
const FirstTextComponent = (props: EffectProps) => {
  const {text, onComplete, containerRef, autoScroll} = props

  return (
    <TypingEffect
      text={text}
      onComplete={onComplete}
      containerRef={containerRef}
      autoScroll={autoScroll}
    />
  )
}

// SecondTextComponent
const SecondTextComponent = (props: EffectProps) => {
  const {text, onComplete, containerRef, autoScroll} = props

  if (!text) {
    onComplete && onComplete()

    return null
  }

  return (
    <TypingEffect
      text={text}
      onComplete={onComplete}
      containerRef={containerRef}
      autoScroll={autoScroll}
      updateCompletion={props.updateCompletion}
    />
  )
}

// ParentComponent
export default function ParentComponent(props: {
  text: string
  onFinish?: () => void
  updateCompletion?: (newValue: string) => void
}) {
  const [showSecondText, setShowSecondText] = useState(false)
  const [autoScroll, setAutoScroll] = useState(true)
  const {contentRef: containerRef} = useLayout()

  const splitText = props.text
    .replaceAll(OBIE_NEW_LINE, '\n')
    .split(OBIE_RESPONSE_SPLITER)

  const handleFirstTextComplete = () => {
    if (!splitText[1]) {
      props.onFinish && props.onFinish()

      return
    }

    setShowSecondText(true)
  }

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current
      if (container) {
        const atBottom =
          Math.ceil(container.scrollTop + container.clientHeight) >=
          container.scrollHeight

        setAutoScroll(atBottom)
      }
    }

    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [containerRef])

  return (
    <Content>
      <FirstTextComponent
        text={splitText[0]}
        onComplete={handleFirstTextComplete}
        containerRef={containerRef}
        autoScroll={autoScroll}
      />
      {showSecondText && (
        <SecondTextComponent
          text={splitText[1]}
          containerRef={containerRef}
          autoScroll={autoScroll}
          onComplete={props.onFinish}
          updateCompletion={props.updateCompletion}
        />
      )}
    </Content>
  )
}

// Styled Component for Typed Text
const TypedText = styled.div<{withPadding?: boolean}>`
  color: white;
  margin-bottom: 16px;
  line-height: 1.5;
  padding: 0
    ${(props) => (props.withPadding ? 'var(--ck-spacing-standard)' : 0)};
`

const Content = styled.div`
  width: 100%;
`
