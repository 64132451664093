import React, {useCallback, useEffect, useState} from 'react'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'

export interface Category {
  id: number
  name: string
}
export interface Block {
  id: number
  block: string
  block_raw: string
  category: Category
  questions: Question[]
  answer_sets: AnswerSet[]
  dependencies: number[]
  prompt: Prompt
}

export interface Prompt {
  block_id: number
  dependencies: number[]
  id: number
  name: string
}

export interface QuestionOption {
  id: number
  path: Question[]
  value: string
  is_other: boolean
}

export interface Question {
  id: number
  block_id: number
  question: string
  question_description: string
  helper_text: string
  example_text: string
  sort: number
  required: boolean
  type: number
  parent_question_id: number
  options: QuestionOption[]
  merge_code: string
  path_id: number
}

export interface Answer {
  id: number
  question_id: number
  question: Question
  answer_set_id: number
  answer: string | string[]
}
export interface AnswerSet {
  id: number
  entity_id: string
  block_id: number
  name: string
  answers: Answer[]
  complete: boolean
  completion: Completion
}

export interface Completion {
  id: number
  answer_set_id: number
  answer_set_name: string
  block_id: number
  prompt_id: number
  completion: string
}

export interface ObieServiceProps {
  deleteAnswerSet: (blockId: number, answerSetId: number) => Promise<void>
  blocks: Block[]
  categories: Category[]
  completion: Completion | undefined
  completions: Completion[]
  createCompletion: (
    answerSetId: number,
    dependencies?: string,
  ) => Promise<Completion>
  copyCompletion: (completionId: number) => Promise<Completion>
  fetchBlocks: () => void
  fetchCompletions: () => void
  findBlock: (block_id: number) => Block | undefined
  getBlock: (category_id: number, block_id: number) => Block
  loadingBlocks: boolean
  loadingCompletions: boolean
  regenerateCompletion: (
    completionId: number,
    dependencies?: string,
  ) => Promise<Completion>
  setCompletion: (completion?: Completion | undefined) => void
  updateAnswerSet: (
    blockId: number,
    answerSetId: number,
    name: string,
  ) => Promise<AnswerSet>
  updateCompletion: (
    completionId: number,
    completion: string,
  ) => Promise<Completion>
}

export const OBIE_RESPONSE_SPLITER = 'ENDOBIERESPONSE'
export const OBIE_NEW_LINE = '[NEW_LINE]'

export const ObieService = React.createContext<ObieServiceProps | undefined>(
  undefined,
)

export default function ObieServiceProvider(props: {
  children: React.ReactElement
}) {
  const fetcherBlocks = useBlocks()
  const fetcherCompletions = useCompletions()
  const deleterAnswerSet = useAnswerSetDelete()
  const updaterAnswerSet = useAnswerSetUpdate()
  const updaterCompletion = useCompletionUpdate()
  const createrCompletion = useCompletionCreate()
  const copierCompletion = useCompletionCopy()
  const regeneratorCompletion = useCompletionRegenerate()

  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const [blocks, setBlocks] = useState<Block[] | null>(null)
  const [loadingBlocks, setLoadingBlocks] = useState<boolean>(false)
  const [categories, setCategories] = useState<Category[] | null>(null)
  const [completion, setCompletion] = useState<Completion | undefined>()
  const [completions, setCompletions] = useState<Completion[]>([])
  const [loadingCompletions, setLoadingCompletions] = useState<boolean>(false)

  const getBlock = (category_id: number, block_id: number): Block => {
    const block = (blocks || []).find(
      (b) => b.id === block_id && b.category.id === category_id,
    )
    if (!block) {
      throw new Error(`(get) Invalid Block ID: ${block_id}, ${category_id}`)
    }
    return block
  }

  const findBlock = (block_id: number): Block | undefined => {
    const block = (blocks || []).find((b) => b.id === block_id)
    if (!block) {
      return
    }
    return block
  }

  const fetchBlocks = useCallback(() => {
    setLoadingBlocks(true)

    return fetcherBlocks().then((response) => {
      setBlocks(response || [])

      const categories: Category[] = []

      const _ = (response || []).map((block: Block) => {
        return categories.find((c: Category) => c.id === block.category.id)
          ? null
          : categories.push(block.category)
      })

      setCategories(categories)
      setLoadingBlocks(false)
    })
  }, [fetcherBlocks, setBlocks, setCategories, setLoadingBlocks])

  const fetchCompletions = useCallback(() => {
    setLoadingCompletions(true)

    return fetcherCompletions().then((response) => {
      setCompletions(response || [])
      setLoadingCompletions(false)
    })
  }, [fetcherCompletions, setCompletions, setLoadingCompletions])

  const deleteAnswerSet = useCallback(
    (blockId: number, answerSetId: number) => {
      return deleterAnswerSet(blockId, answerSetId).then(fetchBlocks)
    },
    [deleterAnswerSet, fetchBlocks],
  )

  const createCompletion = useCallback(
    (answerSetId: number, dependencies?: string) => {
      return createrCompletion(answerSetId, dependencies).then((response) => {
        fetchBlocks()
        fetchCompletions()

        return response
      })
    },
    [createrCompletion, fetchBlocks, fetchCompletions],
  )

  const updateCompletion = useCallback(
    (completionId: number, completion: string) => {
      return updaterCompletion(completionId, completion).then((response) => {
        fetchCompletions()

        return response
      })
    },
    [updaterCompletion, fetchCompletions],
  )

  const copyCompletion = useCallback(
    (completionId: number) => {
      return copierCompletion(completionId).then((response) => {
        return fetchBlocks()
          .then(() => fetchCompletions())
          .then(() => response)
      })
    },
    [copierCompletion, fetchBlocks, fetchCompletions],
  )

  const regenerateCompletion = useCallback(
    (completionId: number, dependencies?: string) => {
      return regeneratorCompletion(completionId, dependencies).then(
        (response) => {
          return fetchBlocks()
            .then(() => fetchCompletions())
            .then(() => response)
        },
      )
    },
    [regeneratorCompletion, fetchBlocks, fetchCompletions],
  )

  const updateAnswerSet = useCallback(
    (blockId: number, answerSetId: number, name: string) => {
      return updaterAnswerSet(blockId, answerSetId, name).then((response) => {
        fetchBlocks()
        return response
      })
    },
    [updaterAnswerSet, fetchBlocks],
  )

  // Whenever blocks and categories are updated, we make sure both of them are
  // available, which tells us that we're done the initial load. We want to know
  // this so that we don't show the "Loading" page when refreshing content after
  // changes.
  useEffect(() => {
    if (blocks !== null && categories !== null) {
      setInitialLoad(false)
    }
  }, [blocks, categories, setInitialLoad])

  // Inital load!
  useEffect(() => {
    fetchBlocks()
    fetchCompletions()
  }, [fetchBlocks, fetchCompletions])

  if (initialLoad) {
    return <FullPageLoader />
  }

  return (
    <ObieService.Provider
      value={{
        blocks: blocks || [],
        categories: categories || [],
        completion,
        completions,
        copyCompletion,
        createCompletion,
        deleteAnswerSet,
        fetchBlocks,
        fetchCompletions,
        findBlock,
        getBlock,
        loadingBlocks,
        loadingCompletions,
        regenerateCompletion,
        setCompletion,
        updateAnswerSet,
        updateCompletion,
      }}
    >
      {props.children}
    </ObieService.Provider>
  )
}

export function useObieService() {
  const context = React.useContext(ObieService)
  if (context === undefined) {
    throw new Error(`useObieService must be used within a ObieServiceProvider`)
  }

  return context
}

function useBlocks() {
  const {client, organization} = useOrganization()
  const url = api(
    `/organizations/${organization.id}/obie/blocks?with=category,questions,answerSets,answerSets.answers`,
  )

  return useCallback(() => client.get<Block[]>(url), [url, client])
}

export function useCompletions() {
  const {client, organization} = useOrganization()
  const url = api(`/organizations/${organization.id}/obie/event/completions`)

  return useCallback(() => client.get<Completion[]>(url), [client, url])
}

export function useCompletionCopy() {
  const {client, organization} = useOrganization()

  return useCallback(
    (completion_id: number) => {
      const url = api(
        `/organizations/${organization.id}/obie/event/completions/${completion_id}/copy`,
      )

      return client.get<Completion>(url)
    },
    [client, organization.id],
  )
}

export function useCompletionCreate() {
  const {client, organization} = useOrganization()
  const url = api(`/organizations/${organization.id}/obie/event/completions`)

  return useCallback(
    (answer_set_id: number, dependencies?: string) => {
      return client.post<Completion>(url, {
        answer_set_id: answer_set_id,
        dependencies: JSON.parse(dependencies || '{}'),
      })
    },
    [client, url],
  )
}

export function useCompletionUpdate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (completionId: number, completion: string) => {
      const url = api(
        `/organizations/${organization.id}/obie/event/completions/${completionId}`,
      )
      return client.put<Completion>(url, {
        completion: completion,
      })
    },
    [client, organization.id],
  )
}

export function useCompletionRegenerate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (completionId: number, dependencies?: string) => {
      const url = api(
        `/organizations/${organization.id}/obie/event/completions/${completionId}/regenerate`,
      )
      return client.post<Completion>(url, {
        dependencies: dependencies,
      })
    },
    [client, organization.id],
  )
}

export function useAnswerSetCreate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (blockId: number, name?: string) => {
      const url = api(
        `/organizations/${organization.id}/obie/blocks/${blockId}/answer-sets`,
      )

      let data = {}

      if (name) {
        data = {name: name}
      }

      return client.post<AnswerSet>(url, data)
    },
    [client, organization],
  )
}

export function useAnswerSetDelete() {
  const {client, organization} = useOrganization()

  return useCallback(
    (blockId: number, answerSetId: number) => {
      const url = api(
        `/organizations/${organization.id}/obie/blocks/${blockId}/answer-sets/${answerSetId}`,
      )
      return client.delete<void>(url)
    },
    [client, organization],
  )
}

export function useAnswerSetUpdate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (blockId: number, answerSetId: number, name: string) => {
      const url = api(
        `/organizations/${organization.id}/obie/blocks/${blockId}/answer-sets/${answerSetId}`,
      )
      return client.put<AnswerSet>(url, {name: name})
    },
    [client, organization],
  )
}
