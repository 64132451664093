import React from 'react'
import styled, {keyframes} from 'styled-components'
import Button from 'lib/ui/Button'
import MarkdownEditor from 'lib/ui/form/TextEditor/MarkdownEditor'
import Grid from 'lib/ui/Grid'
import {ArrowRight} from 'lib/ui/Icon'
import HelperText from 'lib/ui/TextField/HelperText'
import FormQuestionContent from 'organization/Obie/Blocks/ProcessForm/Form/FormQuestionContent'
import {
  FormQuestionProps,
  useObieQuestions,
} from 'organization/Obie/ObieQuestionsProvider'
import {Question} from 'organization/Obie/ObieServiceProvider'

export default function FormQuestion(props: FormQuestionProps) {
  const {question} = props
  const {
    animatingQuestions,
    animationIn,
    animationOut,
    currentQuestionId,
    currentQuestionNumber,
    goToPrevious,
    renderPathQuestion,
    submissionError,
  } = useObieQuestions()

  const isActive = question.id === currentQuestionId
  const isAnimating = animatingQuestions.find(
    (questionId) => questionId === question.id,
  )

  const animation =
    (isActive ? animationIn : null) || (isAnimating ? animationOut : '')

  return (
    <>
      <QuestionContainer
        key={question.id}
        animation={animation}
        isActive={isActive}
      >
        <Grid container spacing={3}>
          <Grid item md={2}>
            <Steper>
              <Title>{currentQuestionNumber}</Title>
              <IconBox>
                <ArrowRight iconSize={36} />
              </IconBox>
            </Steper>
          </Grid>
          <Grid item sm={12} md={8}>
            <Title>{question.question}</Title>

            <QuestionDescription description={question.question_description} />
            <FormQuestionContent {...props} />

            <HelperText error={Boolean(submissionError)}>
              {submissionError || question.helper_text}
            </HelperText>
            <ButtonBox>
              <Button
                type="button"
                variant="contained"
                color="grey"
                onClick={goToPrevious}
              >
                Back
              </Button>
              <SubmitButton question={question} />
            </ButtonBox>
          </Grid>
        </Grid>
      </QuestionContainer>

      {(question.options || []).map((option) =>
        (option.path || []).map((pathQuestion) =>
          renderPathQuestion(pathQuestion),
        ),
      )}
    </>
  )
}

function QuestionDescription(props: {description?: string}) {
  const {description} = props

  if (!description) {
    return null
  }

  return (
    <TypedText>
      <MarkdownEditor data={description} theme="Dark" />
    </TypedText>
  )
}

function SubmitButton(props: {question: Question}) {
  const {question} = props
  const {currentAnswer, goToNext, isSubmitting} = useObieQuestions()

  const isDisabled =
    isSubmitting || (question.required && !Boolean(currentAnswer))

  const handleClick = () => {
    goToNext()
  }

  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      onClick={handleClick}
      disabled={isDisabled}
    >
      Next
    </Button>
  )
}

const TypedText = styled.div<{withPadding?: boolean}>`
  color: white;
  margin-bottom: 16px;
  line-height: 1.5;
  padding: 0
    ${(props) => (props.withPadding ? 'var(--ck-spacing-standard)' : 0)};
`

const slideInFromRight = keyframes`
  0% {
    display: none;
    opacity: 0;
    transform: translateX(110%);
  }
  1% {
    display: block;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideOutToLeft = keyframes`
  0% {
    display: block;
    opacity: 1;
    transform: translateX(0);
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
    transform: translateX(-110%);
  }
`

const slideInFromLeft = keyframes`
  0% {
    display: none;
    opacity: 0;
    transform: translateX(-110%);
  }
  1% {
    display: block;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideOutToRight = keyframes`
  0% {
    display: block;
    opacity: 1;
    transform: translateX(0);
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
    transform: translateX(110%);
  }
`

const QuestionContainer = styled.div<{
  isActive: boolean
  animation: string
}>`
  position: absolute;
  top: 0;
  left: ${(props) => props.theme.spacing[6]};
  right: ${(props) => props.theme.spacing[6]};
  transform: ${(props) =>
    props.isActive ? 'translateX(0)' : 'translateX(110%)'};
  animation: ${(props) =>
      props.animation === 'slideInFromRight'
        ? slideInFromRight
        : props.animation === 'slideOutToLeft'
        ? slideOutToLeft
        : props.animation === 'slideInFromLeft'
        ? slideInFromLeft
        : props.animation === 'slideOutToRight'
        ? slideOutToRight
        : ''}
    0.5s forwards;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    left: 0;
    right: 0;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  color: #ffffff;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: ${(props) => props.theme.spacing[2]};
`

const Steper = styled.div`
  display: flex;
  float: right;
  color: ${(props) => props.theme.colors.white};
  gap: ${(props) => props.theme.spacing[2]};
`

const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ButtonBox = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.spacing[6]};
  gap: ${(props) => props.theme.spacing[2]};
`
