import React, {useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import {categoryRoute} from 'organization/Obie/ObieRoutes'
import NameCompletionDialog from 'organization/Obie/Blocks/ProcessForm/NameCompletionDialog'
import {Completion, useObieService} from 'organization/Obie/ObieServiceProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import TypingEffect from './TypeEffect'

export default function CompletionInfo(props: {
  category_id: string
  completion?: Completion
  dependencies?: string
}) {
  const {completion} = props

  if (!completion) {
    return null
  }

  return <Complete {...props} completion={completion} />
}

export function Complete(props: {
  category_id: string
  completion: Completion
  dependencies?: string
}) {
  const {category_id, completion, dependencies} = props
  const {routes} = useOrganization()
  const history = useHistory()
  const {findBlock, regenerateCompletion} = useObieService()

  const [allTyped, setAllTyped] = useState(false)
  const [nameDialogOpen, setNameDialogOpen] = useState<boolean>(false)
  const [localCompletion, setLocalCompletion] = useState<Completion | null>(
    completion,
  )
  const [completionText, setCompletionText] = useState(completion.completion)

  const toggleNameCompletionDialog = () =>
    setNameDialogOpen((current) => !current)

  const viewCompletions = () => {
    const cRoute = categoryRoute(routes, category_id)
    history.push(cRoute.root)
  }

  const regenerate = useCallback(() => {
    if (!localCompletion) {
      return
    }

    setLocalCompletion(null)

    regenerateCompletion(localCompletion.id, dependencies).then((response) => {
      setLocalCompletion(response)
      setCompletionText(response.completion)
      setAllTyped(false)
    })
  }, [localCompletion, dependencies, regenerateCompletion])

  if (!localCompletion) {
    return null
  }

  const block = findBlock(localCompletion.block_id)

  return (
    <Container>
      <NameCompletionDialog
        completion={localCompletion}
        completionText={completionText}
        onClose={toggleNameCompletionDialog}
        viewCompletions={viewCompletions}
        open={nameDialogOpen}
      />
      <TypingEffect
        text={localCompletion.completion}
        onFinish={() => setAllTyped(true)}
        updateCompletion={setCompletionText}
      />
      <ButtonBox>
        <SaveCompletionButton
          type="button"
          variant="contained"
          color="primary"
          showing={allTyped}
          onClick={toggleNameCompletionDialog}
        >
          Save {block?.block || 'Block'}
        </SaveCompletionButton>
        <RegenerateCompletionButton
          type="button"
          variant="contained"
          color="primary"
          showing={allTyped}
          onClick={regenerate}
        >
          Regenerate
        </RegenerateCompletionButton>
      </ButtonBox>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonBox = styled.div`
  display: flex;
  margin: ${(props) => props.theme.spacing[8]} 0;
  padding-bottom: ${(props) => props.theme.spacing[8]};
`

const SaveCompletionButton = styled(Button)<{showing?: boolean}>`
  display: ${(props) => (props.showing ? 'block' : 'none')};
  margin-right: ${(props) => props.theme.spacing[4]};
`

const RegenerateCompletionButton = styled(Button)<{showing?: boolean}>`
  display: ${(props) => (props.showing ? 'block' : 'none')};
  margin-left: ${(props) => props.theme.spacing[4]};
`
